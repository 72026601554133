import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Privacy() {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Container>
        <h2>Privacy Policy</h2>
        <div className="copy__wrapper">
          <h3>
            We collect information or data about you when you use the safetylogz
            website This may include:
          </h3>
          <p>
            <ul>
              <li>
                {" "}
                questions, queries or feedback you leave, including your email
                address and phone number if you contact us.
              </li>
              <li>
                your IP address, and details of which version of web browser you
                used.
              </li>

              <li>
                information on how you use the site, your use of cookies and
                page tagging techniques to help us improve the website.
              </li>
            </ul>
          </p>
          <h3>This helps us to:</h3>
          <p>
            improve the site by monitoring how you use it respond to any
            feedback or questions you send us, if you’ve asked us to.
          </p>
          <h3>Where your data is stored:</h3>
          <ul>
            <li>We store your data on a secure server.</li>
            <li>It may also be stored outside of Europe.</li>
            <li>By submitting your personal data, you agree to this.</li>
          </ul>
          <h3>Keeping your data secure:</h3>
          <p>
            Transmitting information over the public Internet is generally not
            completely secure, and we can’t guarantee the security of your data.
          </p>
          <p>
            Any data you transmit is at your own risk. We have procedures and
            security features in place to try to keep your data secure once we
            receive it.
          </p>
          <p>
            We won’t share your information with any other organisations for
            marketing, market research or commercial purposes, and we don’t pass
            on your details to other websites, unless you give us consent to do
            so.
          </p>
          <h3>Disclosing your information:</h3>
          <p>
            We may pass on your personal information if we have a legal
            obligation to do so, or if we have to enforce or apply our terms of
            use and other agreements.
          </p>

          <h3>Your rights:</h3>
          <p>
            You can find out what information we hold about you, and ask us not
            to use any of the information we collect.
          </p>
          <h3>Links to other websites:</h3>
          <p>
            The safetylogz website may contain links to and from other websites.
            This privacy policy only applies to this website, and doesn’t cover
            other services that we link to.
          </p>
          <p>
            If you go to another website from this one, read the privacy policy
            on that website to find out what it does with your information.
          </p>
        </div>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  height: 100%;
  @media only screen and (max-width: 414px) {
    flex-direction: row;
    flex-wrap: wrap;
    .copy__wrapper {
      margin-bottom: 110px;
      padding-bottom: 20px;
    }
  }
`
